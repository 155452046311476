import { mdiCommentTextMultipleOutline } from '@mdi/js'
import React, { FC, ReactNode, useCallback, useMemo, useRef, useState, } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormState } from '../../hooks/useFormState'
import { ChildrenProp } from '../../jsx'
import { ApiTask, ApiTaskFormFieldBase } from '../../services/api/apiSchemas'
import { RequestStatus } from '../../services/api/generic/types'
import { useProductDetailStore } from '../../stores/productDetailStore'
import { booleanFromString } from '../../utils/boolean'
import { Button } from '../Forms/Button'
import { ButtonRow } from '../Forms/ButtonRow'
import { Checkbox } from '../Forms/Checkbox'
import { Input } from '../Forms/Input'
import { Select } from '../Forms/Select'
import { Textarea } from '../Forms/Textarea'
import { Modal } from '../Generic/Modal'
import { ModalHeadline } from '../Generic/ModalHeadline'
import { RequestErrorMessage } from '../Generic/RequestErrorMessage'
import { CommentList } from '../ItemViews/CommentList'

export interface CompleteTaskModalSubmitParams {
  comment: string
  [key: string]: string
}

interface Props extends ChildrenProp {
  task: ApiTask;
  visible: boolean;
  onSubmit: (values: CompleteTaskModalSubmitParams) => void;
  onVisibleChange: (value: boolean) => void;
  taskActionStatus: number;
}

export const CompleteTaskModal: FC<Props> = ({
  task,
  visible,
  onSubmit,
  onVisibleChange,
  taskActionStatus,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null)
  const [comment, setComment] = useState('')
  const {
    formState,
    formFieldElements
  } = useFormFields(task.formFields)
  const { t } = useTranslation()

  function handleSubmitClick (): void {
    const isValid = formRef.current?.reportValidity() ?? false
    if (!isValid) return

    onSubmit({
      ...formState,
      comment
    })
  }

  function handleCancelClick (): void {
    onVisibleChange(false)
  }

  return (
    <Modal
      visible={visible}
      title={t('completeTaskModal.title', { name: task.dataResource.name })}
      type="complete-task"
      width="normal"
      padding="small"
      verticalAlign="center"
      children={
        <>
          <form ref={formRef}>
            {formFieldElements}

            <Textarea
              label={t('global.comment.singular')}
              name="comment"
              value={comment}
              disabled={taskActionStatus === RequestStatus.pending}
              onInput={setComment}
            />
          </form>

          <ModalHeadline
            text={t('global.comment.plural')}
            icon={mdiCommentTextMultipleOutline}
          />
          <CommentList items={task.comments ?? []} />
        </>
      }
      footer={
        <>
          <RequestErrorMessage status={taskActionStatus} />

          <ButtonRow align="left">
            <Button
              type="submit"
              disabled={task.taskStatus === RequestStatus.pending}
              onClick={handleSubmitClick}
            >
              {t('completeTaskModal.confirmButton')}
            </Button>

            <Button
              type="button"
              buttonStyle="secondary"
              disabled={task.taskStatus === RequestStatus.pending}
              onClick={handleCancelClick}
            >
              {t('common.cancel')}
            </Button>
          </ButtonRow>
        </>
      }
    />
  )
}

interface UseFormFieldsReturn {
  formState: Record<string, unknown>;
  formFieldElements: ReactNode;
}

export function useFormFields (
  formFields?: ApiTask['formFields'],
): UseFormFieldsReturn {
  const {
    formState,
    updateFormState
  } = useFormState(formFields)
  const { taskActionStatus } = useProductDetailStore()
  const { t } = useTranslation()

  const getConstraintsProps = useCallback(
    (props: ApiTaskFormFieldBase['constraints']) => {
      const entries = props
        .map(({
          name,
          config
        }) => {
          if (name === 'readonly' || name === 'required') {
            const value = booleanFromString(config ?? 'true')
            if (value === undefined) {
              return [name, true]
            }
            return [name, value]
          }
          return [name, config]
        })
        .filter(([key, value]) => value !== undefined)

      return Object.fromEntries(entries)
    },
    [],
  )

  const formFieldElements = useMemo(
    () =>
      formFields?.map((formField) => {
        const value = formState[formField.id]

        switch (formField.type) {
          case 'boolean': {
            if (typeof value !== 'boolean') {
              return null
            }

            return (
              <Checkbox
                key={formField.id}
                name={formField.id}
                checked={value}
                children={formField.label}
                disabled={taskActionStatus === RequestStatus.pending.valueOf()}
                checkboxProps={getConstraintsProps(formField.constraints)}
                onChange={(value) => {
                  updateFormState(formField.id, value)
                }}
              />
            )
          }

          case 'long': {
            if (typeof value !== 'string') {
              return null
            }

            return (
              <Input
                key={formField.id}
                label={formField.label}
                name={formField.id}
                value={value}
                disabled={taskActionStatus === RequestStatus.pending}
                inputProps={getConstraintsProps(formField.constraints)}
                onInput={(value) => {
                  updateFormState(formField.id, value)
                }}
              />
            )
          }

          case 'string': {
            if (typeof value !== 'string') {
              return null
            }

            return (
              <Textarea
                key={formField.id}
                label={formField.label}
                name={formField.id}
                value={value}
                disabled={taskActionStatus === RequestStatus.pending}
                textareaProps={getConstraintsProps(formField.constraints)}
                onInput={(value) => {
                  updateFormState(formField.id, value)
                }}
              />
            )
          }

          case 'date': {
            if (typeof value !== 'string') {
              return null
            }

            return (
              <Input
                key={formField.id}
                type="date"
                label={formField.label}
                name={formField.id}
                value={value}
                disabled={taskActionStatus === RequestStatus.pending}
                inputProps={getConstraintsProps(formField.constraints)}
                onInput={(value) => {
                  updateFormState(formField.id, value)
                }}
              />
            )
          }

          case 'enum': {
            const items = Object.fromEntries(
              formField.enumValues.map(({
                id,
                value
              }) => [id.toString(), value]),
            )

            return (
              <Select
                key={formField.id}
                items={items}
                value={value?.toString()}
                name={formField.id}
                label={formField.label}
                placeholder={t('global.formfield.select.placeholder')}
                disabled={taskActionStatus === RequestStatus.pending}
                selectProps={getConstraintsProps(formField.constraints)}
                onChange={(value) => {
                  updateFormState(formField.id, value)
                }}
              />
            )
          }

          default:
            return null
        }
      }),
    [
      formFields,
      formState,
      getConstraintsProps,
      t,
      taskActionStatus,
      updateFormState,
    ],
  )

  return {
    formState,
    formFieldElements
  }
}
