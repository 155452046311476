import { mdiCheckOutline, mdiDotsHorizontal, mdiEyeCheck, mdiEyeOff, mdiPlayOutline } from '@mdi/js'
import { ApiTaskDataresourceAssetCoAuthor } from 'chbeckportal_interfaces/ApiTaskInterfaces'
import { changeCoAuthorReleaseCustom } from 'sfportal_stores_extensions/productDetailStoreExtension'
import React, { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonDropdown } from 'sfportal_components_forms/ButtonDropdown'
import { IListGroupTaskUtils, Props } from 'sfportal_components_listitem_utils_interfaces/IListGroupTaskUtils'
import { MenuItem } from 'sfportal_components_menu/MenuItem'
import { MenuTitle } from 'sfportal_components_menu/MenuTitle'
import { ApiTask, ApiTaskDataResourceAsset, instanceOfApiTaskDataResourceAsset } from 'sfportal_services_api/apiSchemas'
import { RequestStatus } from 'sfportal_services_api/generic/types'
import { claimTasks, loadFormfieldsForTask, useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { useUserStore } from 'sfportal_stores/userStore'
import { noop } from 'sfportal_utils/function'
import { useCompleteMultipleTasksModal } from '../../../../../components/ListItem/hooks/useCompleteMultipleTaskModal'

/**
 * DropDown für den Header von Gruppen.
 */
const GroupHeaderDropDown = memo(function GroupHeaderDropDown ({
  tasks,
  title
}: Props) {
  const { currentUser } = useUserStore()
  const { taskActionStatus } = useProductDetailStore()
  const { t } = useTranslation()

  /**
   * Gibt an, ob der coauthor change gerade ausgeführt wird.
   */
  const [coAuthorChangeIsPending, setCoAuthorChangeIsPending] = useState(false)

  /**
   * Array mit allen Tasks, die bereits dem User zugeordnet sind.
   */
  const claimedTasks = useMemo(() => {
    if (currentUser === null) {
      return []
    }
    return tasks.filter((task) => task.assignee === currentUser.username)
  }, [currentUser, tasks])

  /**
   * Array mit allen Tasks, die der User theoretisch abschließen kann
   */
  const tasksUserCanComplete = useMemo(() => {
    if (currentUser === null) {
      return []
    }
    return tasks.filter((task) => task.assignee === currentUser.username || !task.assignee && task.taskPrivileges.canUserClaim)
  }, [currentUser, tasks])

  const { completeMultipleTasksModal, setCompleteMultipleTasksModalVisible } =
    useCompleteMultipleTasksModal({ tasks: claimedTasks })

  const { completeMultipleTasksModal: completeMultipleTasksModalCanComplete, setCompleteMultipleTasksModalVisible: setCompleteMultipleTasksModalVisibleCanComplete } =
      useCompleteMultipleTasksModal({ tasks: tasksUserCanComplete })

  /**
   * Array mit allen Tasks, die der User übernehmen kann.
   */
  const claimableTasks = useMemo(() => {
    return tasks.filter(
      (task) => !task.assignee && task.taskPrivileges.canUserClaim
    )
  }, [tasks])

  /**
   * Gibt an ob der aktuell angemeldete User Tasks übernehmen kann.
   */
  const currentUserCanClaimTasks = useMemo(
    () => claimableTasks.length > 0,
    [claimableTasks.length]
  )

  /**
   * Übernimmt alle Tasks, die dem angemeldeten User zugeordnet werden können.
   */
  function handleClaimAllTasks (): void {
    const taskIds = claimableTasks.map((task) => task.id)
    claimTasks(...taskIds).catch(noop)
  }

  /**
   * Schließt alle dem User zurgeordneten Tasks ab.
   */
  async function handleCompleteAllTasks (): Promise<void> {
    if (claimedTasks.filter((claimedTask: ApiTask) => claimedTask.formFields !== undefined).length === 0) {
      // Keines der Tasks hat die formfelder geladen. Da allerdings jeder task der gleiche "tasktype" ist,
      // reicht es den ersten zu laden. Diese Tasks werden dann verwendet.
      await loadFormfieldsForTask(claimedTasks[0].id)
    }
    setCompleteMultipleTasksModalVisible(true)
  }

  /**
   * Schließt alle Aufgaben ab,
   */
  async function completeTasksForAllFiles (): Promise<void> {

    if (tasksUserCanComplete.filter((taskUserCanComplete: ApiTask) => taskUserCanComplete.formFields !== undefined).length === 0) {
      // Keines der Tasks hat die formfelder geladen. Da allerdings jeder task der gleiche "tasktype" ist,
      // reicht es den ersten zu laden. Diese Tasks werden dann verwendet.
      await loadFormfieldsForTask(tasksUserCanComplete[0].id)
    }
    setCompleteMultipleTasksModalVisibleCanComplete(true)
  }

  /**
   * Boolean der angibt, ob gerade eine Action läuft, wordurch die Taskbuttons in der Ui gesperrt werden.
   */
  const taskActionIsPending = useMemo(
    () => taskActionStatus === RequestStatus.pending,
    [taskActionStatus]
  )

  /**
   * Gibt an ob dem aktuellen User Tasks zugeodnet sind.
   */
  const currentUserHasTasksAssigned = useMemo(
    () => claimedTasks.length > 0,
    [claimedTasks.length]
  )

  /**
   * Array mit allen Tasks, welche für Co Autoren freigegeben werden können.
   */
  const grantableTasks = useMemo(
    () =>
      tasks.filter(
        (task) =>
          (task.dataResource as ApiTaskDataResourceAsset).assetPrivileges?.canUserGrantReleaseForCoAuthor &&
          (!(task.dataResource as ApiTaskDataresourceAssetCoAuthor).hasGrantedReleaseForCoAuthor ?? true)
      ),
    [tasks]
  )

  /**
   * Array mit allen Tasks, welche die Autorenfreigabe genommen werden kann.
   */
  const removeableGrantTasks = useMemo(
    () =>
      tasks.filter(
        (task) =>
          (task.dataResource as ApiTaskDataResourceAsset).assetPrivileges?.canUserGrantReleaseForCoAuthor &&
          ((task.dataResource as ApiTaskDataresourceAssetCoAuthor).hasGrantedReleaseForCoAuthor ?? false)
      ),
    [tasks]
  )

  /**
   * Gibt an ob Tasks fur Co Autoren freigegeben werden können
   */
  const canGrantCoAuthorAccess = useMemo(
    () => grantableTasks.length > 0,
    [grantableTasks.length]
  )

  /**
   * Gibt an ob Tasks die Mitautorenfreigabe entnommen werden kann.
   */
  const canRemoveCoAuthorAccess = useMemo(
    () => removeableGrantTasks.length > 0,
    [removeableGrantTasks.length]
  )

  /**
   * Ändert die Mitautorenfreigabe für eine Array an Tasks.
   */
  async function changeGrantsForCoAuthors (
    coAuthorGrantableTasks: ApiTask[]
  ): Promise<void> {
    // Der Button soll disabled werden, solange der Statechange auf der Datenbank läuft.
    setCoAuthorChangeIsPending(true)
    await Promise.all(
      coAuthorGrantableTasks.map(
        async (task) => await changeCoAuthorReleaseCustom(task.dataResource.id)
      )
    )
    setCoAuthorChangeIsPending(false)
  }

  /**
   * Handelt den Klick für freigabe aller Dokumente für Mitautoren
   */
  async function grantReleaseForCoWorkerAllTasks (): Promise<void> {
    await changeGrantsForCoAuthors(grantableTasks)
  }

  /**
   * Handelt den Klick für das Entfernen der Mitautorenfreigabe aller Dokumente.
   */
  async function revokeReleaseForCoWorkerAllTasks (): Promise<void> {
    await changeGrantsForCoAuthors(removeableGrantTasks)
  }

  /**
   * Gibt an ob die Assets aller Tasks entsperrt sind
   */
  const tasksUnlocked = useMemo(
    () =>
      tasks.filter((task: ApiTask) => {
        if (instanceOfApiTaskDataResourceAsset(task.dataResource)) {
          return task.dataResource.locked
        }

        return false
      }).length === 0,
    [tasks]
  )

  if (claimableTasks.length <= 0 && claimedTasks.length <= 0) {
    return null
  }

  return (
    <>
      {completeMultipleTasksModal}
      {completeMultipleTasksModalCanComplete}

      <ButtonDropdown
        buttonStyle="inline"
        buttonIcon={mdiDotsHorizontal}
        title={t('listItemGroupTask.moreActions')}
      >
        <MenuTitle
          label={t('listItemGroupTask.menuTitle', {
            count: tasks.length,
            title
          })}
        />

        {canGrantCoAuthorAccess ? (
          <MenuItem
            label={t('listItemGroupTask.grantReleaseForCoWorker')}
            icon={mdiEyeCheck}
            action={grantReleaseForCoWorkerAllTasks}
            enabled={!coAuthorChangeIsPending}
          />
        ) : null}

        {canRemoveCoAuthorAccess ? (
          <MenuItem
            label={t('listItemGroupTask.revokeReleaseForCoWorker')}
            icon={mdiEyeOff}
            action={revokeReleaseForCoWorkerAllTasks}
            enabled={!coAuthorChangeIsPending}
          />
        ) : null}

        {currentUserCanClaimTasks ? (
          <MenuItem
            label={t('listItemGroupTask.claimAllTasks')}
            icon={mdiPlayOutline}
            action={handleClaimAllTasks}
            enabled={!taskActionIsPending}
          />
        ) : null}

        {currentUserHasTasksAssigned ? (
          <MenuItem
            label={t('listItemGroupTask.completeAllTasks')}
            icon={mdiCheckOutline}
            action={handleCompleteAllTasks}
            enabled={!taskActionIsPending && tasksUnlocked}
          />
        ) : null}
        <MenuItem
            label={t('listItemGroupTask.completeTasksForAllFiles')}
            icon={mdiCheckOutline}
            action={completeTasksForAllFiles}
        />
      </ButtonDropdown>
    </>
  )
})

/**
 * Utils mit Funktionen und Komponenten zu Task Gruppen.
 */
export const ListItemGroupTaskUtils: IListGroupTaskUtils = {
  /**
   * DropDown für den Header von Gruppen.
   */
  GroupHeaderDropdown: GroupHeaderDropDown
}
