import { StaticStompClient } from '../services/multicast/staticStompClient'
import { DependencyList, useEffect } from 'react'
import { noop } from '../utils/function'
import { Client, Message } from '@stomp/stompjs'

/**
 * Type für die Art der Subscription auf den Multicaster.
 */
type MulticastType = 'create' | 'update' | 'delete' | 'lockCreate' | 'lockDelete' | 'complete' | 'start' | 'end' | '*' | 'externalTask'

/**
 * Das Topic der Subscription
 * - entity: Alle Susbcriptions auf entities.
 * - workflow: Alle Subscriptions auf alle Workflows.
 * - workflow_extern: Alle Subscriptions auf Workflowtasks mit der Extension 'isExternal'
 */
type Topic = 'entity' | 'workflow' | 'workflow_extern'

/**
 * Hook um eine Subscription ins Backend zu erstellen. Die Subscription wird auch in das Backend
 * von Sitefusion weitergeleitet um auf Änderungen reagieren zu können. Der Hook sorgt dafür, dass
 * sich die subscriptions selbst disconnecten (Frontend und Backend Subscription).
 *
 * @param dataresource Die Dataresource auf die subscribed wird.
 * @param type Der Subscrption Type.
 * @param id Die Id auf die subscribed wird.
 * @param callback Der Callback, der ausgeführt wird wenn die Subscription getriggert wird.
 * @param topic Das zu subscribende Topic. Default auf entity
 */
export const useSubscription = (
  dataresource: string | undefined,
  type: MulticastType,
  id: string | undefined,
  callback: (message: Message) => void,
  topic: Topic = 'entity'
): void => {
  /**
   * Funktion zum unsubscribe der subscription.
   * Diese wird mit Erstellung der Subscription überschrieben, da der unsubscribe immer erst mit der Subscription
   * zur Verfügung steht.
   */
  let unsubscribe: () => void

  useEffect(() => {
    if (dataresource === undefined || topic === undefined || id === undefined) {
      return
    }

    StaticStompClient.getInstance().then(async (stompClient: Client): Promise<void> => {
      const subscriptionUrl = '/topic/ext.notify.' + (topic === 'workflow_extern' ? 'workflow.extern' : topic) + '.' + dataresource + '.' + type + '.' + id
      const subscription = stompClient.subscribe(
        subscriptionUrl,
        callback,
        {}
      )

      // Erstellt die neue unsubscribe Methode.
      // Falls die unsubscribe nicht außerhalb definiert ist,
      // wird beim return immer der default Wert ausgeführt,
      // da der return innerhalb eines promises erstellt wird und somit asynchron geändert wird.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      unsubscribe = () => {
        subscription?.unsubscribe()
      }
    }).catch(noop)

    // Bei jeder Änderung der dependencies und beim unmount der Componente wird die subscription geschlossen.
    return () => {
      if (unsubscribe !== undefined) {
        unsubscribe()
      }
    }
  }, [dataresource, type, id, topic, callback])
}
