import { useEffect, useRef } from 'react'
import { noop } from '../utils/function'
import { addAreaSubscriptionCallback, addNotifyCallbacksForStaticAreaIdChanged, removeAreaSubscriptionCallback, staticAreaId } from '../services/multicast/staticAreaSubscription'
import { Message } from '@stomp/stompjs'

/**
 * Type für die Art der Subscription auf den Multicaster.
 */
type MulticastType = 'create' | 'update' | 'delete' | 'lockCreate' | 'lockDelete' | 'complete' | 'start' | 'end' | '*' | 'externalTask' | 'assigneeChanged' | 'workflowTaskAutoOpenExtern'

/**
 * Das Topic der Subscription
 * - entity: Alle Susbcriptions auf entities.
 * - workflow: Alle Subscriptions auf alle Workflows.
 * - workflow_extern: Alle Subscriptions auf Workflowtasks mit der Extension 'isExternal'
 */
type Topic = 'entity' | 'workflow' | 'workflow_extern'

/**
 * Hook um einen Subscription callback an die area subcription zu hängen. Im Backend wird dazu keine subscription erstellt.
 * Wenn die area gepusht wird, wird der eigentliche subscriptionpfad im originalPath mitgegeben worduch alle callbacks auf den path getriggert werden.
 *
 * @param dataresource Die Dataresource auf die subscribed wird.
 * @param type Der Subscrption Type.
 * @param id Die Id auf die subscribed wird.
 * @param callback Der Callback, der ausgeführt wird wenn die Subscription getriggert wird. Der Callback muss als useCallback definiert werden, sonst wird eine infinite loop erstellt.
 * @param topic Das zu subscribende Topic. Default auf entity
 */
export const useAddSubscriptionToArea = (
  dataresource: string | undefined,
  type: MulticastType,
  id: string | undefined,
  callback: (message: Message) => void,
  topic: Topic = 'entity'
): void => {
  const subscriptionId = useRef<string | null>(null)

  useEffect(() => {
    if (dataresource === undefined || topic === undefined || id === undefined) {
      return
    }

    const subscriptionUrl = '/topic/ext.notify.' + (topic === 'workflow_extern' ? 'workflow.extern' : topic) + '.' + dataresource + '.' + type + '.' + id

    if (staticAreaId === null) {
      // Area subscription ist noch nicht da.
      addNotifyCallbacksForStaticAreaIdChanged(() => {
        addAreaSubscriptionCallback(subscriptionUrl, callback).then((subscriptionIdCallback) => {
          if (subscriptionIdCallback === null) {
            // Produkt wurde nicht gesetzt.
            return
          }
          subscriptionId.current = subscriptionIdCallback
        }).catch(noop)
      })
    } else {
      addAreaSubscriptionCallback(subscriptionUrl, callback).then((subscriptionIdCallback) => {
        if (subscriptionIdCallback === null) {
          // Produkt wurde nicht gesetzt.
          return
        }
        subscriptionId.current = subscriptionIdCallback
      }).catch(noop)
    }

    // Bei jeder Änderung der dependencies und beim unmount der Komponente wird die subscription geschlossen.
    return () => {
      if (subscriptionId.current !== null) {
        removeAreaSubscriptionCallback(subscriptionUrl, subscriptionId.current).catch(noop)
      }
    }
  }, [dataresource, type, id, topic, callback])
}
