import {sanitize} from '../routes'

/**
 * Custom function to get the file uri
 * @param {string} emk the emk
 * @param {'preview' | 'download'} type which type
 * @param {boolean} withoutLock if the asset should not be looked
 */
export function cstGetFileUriFromEMK(
    emk: string,
    type: 'preview' | 'download' = 'preview',
    withoutLock?: boolean
): string {
    const baseUri = process.env.REACT_APP_PUBLIC_URL ?? ''

    switch (type) {
        case 'preview':
            return sanitize(`${baseUri}/api/files/${emk}?format=normal`)
        case 'download':
            if (withoutLock) {
                return sanitize(`${baseUri}/api/files/download/withoutLock/${emk}`)
            }
            return sanitize(`${baseUri}/api/files/download/${emk}`)
    }
}
