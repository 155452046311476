import React, { ReactNode, useCallback, useEffect, useMemo, useState, } from 'react'
import { useTranslation } from 'react-i18next'
import { CLAIM_TASK_ICON, COMPLETE_TASK_ICON, STOP_TASK_ICON, } from 'sfportal_components_listitem_constants/taskActionConstants'
import { ApiTask } from '../../services/api/apiSchemas'
import { RequestStatus } from '../../services/api/generic/types'
import { changeAssignee, claimTasks, completeTaskWithComment, loadFormfieldsForTask, unclaimTasks, } from '../../stores/productDetailStore'
import { useUserStore } from '../../stores/userStore'
import { noop } from '../../utils/function'
import { Button } from '../Forms/Button'
import { CompleteTaskModal } from '../Modals/CompleteTaskModal'
import { useAddSubscriptionToArea } from '../../hooks/useAddSubscriptionToArea'

interface UseTaskActionsParams {
  task: ApiTask;
  dataresourceLocked?: boolean;
  taskActionStatus: number;
}

interface UseTaskActionsReturn {
  taskModalElement: ReactNode;
  taskActionElements: ReactNode;
  toggleOpenModal: () => void
}

let assigneeChangedEntries: Record<string, string | null> = {}
let assigneeChangedTimeout: number | null = null

export const useTaskActions = ({
  task,
  dataresourceLocked = false,
  taskActionStatus,
}: UseTaskActionsParams): UseTaskActionsReturn => {
  const [isParentUnmounted, setIsParentUnmounted] = useState(false)
  const { currentUser } = useUserStore()
  const { t } = useTranslation()

  const [completeTaskModalVisible, setCompleteTaskModalVisible] =
    useState(false)

  const assigneeChangedCallback = useCallback((message) => {
    // Hier können sehr viele assignee changes gleichzeitig aufschlagen wenn z.B. der Button alle Tasks annehmen im Portal geklickt wird.
    // Um hier performant zu bleiben, werden die assignees erst aktualisiert wenn man 0.5 sekunden keine assignee subscription bekommen hat.
    const messageBody = JSON.parse(message.body)
    assigneeChangedEntries[messageBody.taskId] =
      messageBody.newAssigneeUsername
    if (assigneeChangedTimeout !== null) {
      window.clearTimeout(assigneeChangedTimeout)
    }
    assigneeChangedTimeout = window.setTimeout(() => {
      changeAssignee(assigneeChangedEntries)
      assigneeChangedEntries = {}
      assigneeChangedTimeout = null
    }, 500)
  }, [])

  // Subscription, falls sich der state des tasks ändert.
  // (angenommen, zurückgegeben)
  useAddSubscriptionToArea(
    'historictask',
    'assigneeChanged',
    task.id,
    assigneeChangedCallback,
    'workflow',
  )

  // Sobald die Komponente, in der dieser useTaskActions-Hook verwendet wird,
  // entfernt (unmounted) wird, wird `isParentUnmounted` auf `true` gesetzt.
  // Ist dieses Flag `true`, darf kein State mehr aktualisiert werden.
  // Das ist in erster Linie in `async functions` nach `await` von Bedeutung.
  useEffect(() => {
    return () => {
      setIsParentUnmounted(true)
    }
  }, [])

  const handleClaimTaskClick = useCallback((): void => {
    claimTasks(task.id).catch(noop)
  }, [task.id])

  const handleUnclaimTaskClick = useCallback((): void => {
    unclaimTasks(task.id).catch(noop)
  }, [task.id])

  const handleCompleteTaskClick = useCallback(async (): Promise<void> => {
    if (task.formFields === undefined) {
      await loadFormfieldsForTask(task.id)
    }
    setCompleteTaskModalVisible(true)
  }, [task.formFields, task.id])

  const handleCompleteTaskModalSubmit = useCallback(
    async ({
      comment,
      ...formFields
    }: Record<string, string>): Promise<void> => {
      await completeTaskWithComment(task.id, {
        formFields,
        comment,
      })

      if (isParentUnmounted) return

      if (taskActionStatus === RequestStatus.ok.valueOf()) {
        setCompleteTaskModalVisible(false)
      }
    },
    [isParentUnmounted, task.id, taskActionStatus],
  )

  const assignedToCurrentUser = useMemo(() => {
    if (currentUser === null) {
      return false
    }
    return (
      task.assignee !== undefined && task.assignee === currentUser.username
    )
  }, [currentUser, task.assignee])

  const taskIsClaimable = useMemo(
    () => !task.assignee && task.taskPrivileges.canUserClaim,
    [task.assignee, task.taskPrivileges.canUserClaim],
  )

  const taskIsUnclaimable = useMemo(() => {
    if (currentUser === null) {
      return false
    }
    return task.assignee === currentUser.username
  }, [currentUser, task.assignee])

  const completeTaskModalElement = useMemo(
    () => (
      <CompleteTaskModal
        task={task}
        taskActionStatus={taskActionStatus}
        visible={completeTaskModalVisible}
        onSubmit={handleCompleteTaskModalSubmit}
        onVisibleChange={(value) => {
          setCompleteTaskModalVisible(value)
        }}
      />
    ),
    [completeTaskModalVisible, handleCompleteTaskModalSubmit, task],
  )

  const taskActionElements = useMemo(
    () => (
      <>
        {taskIsClaimable ? (
          <Button
            buttonStyle="inline"
            title={t('tasks.claim')}
            icon={CLAIM_TASK_ICON}
            onClick={handleClaimTaskClick}
            disabled={task.taskStatus === RequestStatus.pending}
          />
        ) : null}

        {taskIsUnclaimable ? (
          <Button
            buttonStyle="inline"
            title={t('tasks.unclaim')}
            icon={STOP_TASK_ICON}
            onClick={handleUnclaimTaskClick}
            disabled={task.taskStatus === RequestStatus.pending}
          />
        ) : null}

        {assignedToCurrentUser ? (
          <Button
            buttonStyle="inline"
            title={t('tasks.complete')}
            icon={COMPLETE_TASK_ICON}
            onClick={handleCompleteTaskClick}
            disabled={
              task.taskStatus === RequestStatus.pending || dataresourceLocked
            }
          />
        ) : null}
      </>
    ),
    [
      assignedToCurrentUser,
      dataresourceLocked,
      handleClaimTaskClick,
      handleCompleteTaskClick,
      handleUnclaimTaskClick,
      t,
      task.taskStatus,
      taskIsClaimable,
      taskIsUnclaimable,
    ],
  )

  return {
    taskModalElement: completeTaskModalVisible
      ? completeTaskModalElement
      : null,
    taskActionElements,
    toggleOpenModal: () => setCompleteTaskModalVisible(prevState => !prevState)
  }
}
