import { StaticStompClient } from './staticStompClient'
import { v4 as uuidv4 } from 'uuid'
import { noop } from '../../utils/function'
import { Message, StompSubscription } from '@stomp/stompjs'

export interface AreaSubscription {
  generalAreaSubscription: StompSubscription
  specificAreaSubscription: StompSubscription
  areaSubscriptionCallbacks: Record<string, Record<string, (message: Message) => void>>
}

let staticAreaSubscription: Promise<AreaSubscription> | null = null

export let staticAreaId: number | null = null

let callbacksForStaticAreaIdChanged: Array<() => void> = []

function notifyCallbacksForStaticAreaIdChanged (): void {
  callbacksForStaticAreaIdChanged.forEach(singleCallback => singleCallback())
}

export function addNotifyCallbacksForStaticAreaIdChanged (callback: () => void): void {
  callbacksForStaticAreaIdChanged.push(callback)
}

export function setAreaSubscrptionId (areaId: number): void {
  staticAreaId = areaId
  notifyCallbacksForStaticAreaIdChanged()
  callbacksForStaticAreaIdChanged = []
}

export async function getAreaSubscription (areaId: number): Promise<AreaSubscription> {
  if (staticAreaSubscription === null) {
    staticAreaSubscription = createAreaSubscription(areaId)
  }

  return await staticAreaSubscription
}

export async function createAreaSubscription (areaId: number): Promise<AreaSubscription> {
  staticAreaId = areaId
  const subscriptionUrlGeneralArea = '/topic/ext.notify.area.pubchannel'
  const subscriptionUrlSpecificArea = `/topic/ext.notify.area.pubchannel.${staticAreaId}`
  const stompClientInstance = await StaticStompClient.getInstance()
  const generalAreaSubscription = stompClientInstance.subscribe(
    subscriptionUrlGeneralArea,
    executeAreaCallbacks,
    {}
  )

  const specificAreaSubscription = stompClientInstance.subscribe(
    subscriptionUrlSpecificArea,
    executeAreaCallbacks,
    {}
  )

  return {
    generalAreaSubscription,
    specificAreaSubscription,
    areaSubscriptionCallbacks: {}
  }
}

export async function resetAreaSubscription (): Promise<void> {
  if (staticAreaId !== null && staticAreaSubscription !== null) {
    const areaSubscription = await staticAreaSubscription
    areaSubscription.generalAreaSubscription.unsubscribe()
    areaSubscription.specificAreaSubscription.unsubscribe()
  }

  staticAreaSubscription = null
  staticAreaId = null
}

function executeAreaCallbacks (message: Message): void {
  if (staticAreaId === null) {
    return
  }
  const originalDestination: string = JSON.parse(message.body).originalDestination
  const originalDestinationAllTypes: string = JSON.parse(message.body).originalDestinationAllTypes
  const originalDestinationAllIds: string = JSON.parse(message.body).originalDestinationAllIds

  getAreaSubscription(staticAreaId).then(areaSubscription => {
    const callbacks =
      areaSubscription.areaSubscriptionCallbacks[
        originalDestination?.replace('/topic/', '/topic/ext.')
        ]

    const callbacksAllDestinations =
      areaSubscription.areaSubscriptionCallbacks[
        originalDestinationAllTypes?.replace('/topic/', '/topic/ext.')
        ]

    const callbacksAllDestinationsIds =
      areaSubscription.areaSubscriptionCallbacks[
        originalDestinationAllIds?.replace('/topic/', '/topic/ext.')
        ]

    if (callbacks !== undefined) {
      Object.entries(callbacks).forEach(([, entry]) => entry(message))
    }

    if (callbacksAllDestinations !== undefined) {
      Object.entries(callbacksAllDestinations).forEach(([, entry]) => entry(message))
    }

    if (callbacksAllDestinationsIds !== undefined) {
      Object.entries(callbacksAllDestinationsIds).forEach(([, entry]) => entry(message))
    }
  }).catch(noop)
}

export async function addAreaSubscriptionCallback (destination: string, callback: (message: Message) => void): Promise<string | null> {
  if (staticAreaId === null) {
    return null
  }
  const areaSubscription = await getAreaSubscription(staticAreaId)

  const subscriptionId = uuidv4()

  if (!Object.prototype.hasOwnProperty.call(areaSubscription.areaSubscriptionCallbacks, destination)) {
    areaSubscription.areaSubscriptionCallbacks[destination] = {}
  }

  areaSubscription.areaSubscriptionCallbacks[destination][subscriptionId] = callback

  return subscriptionId
}

export async function removeAreaSubscriptionCallback (destination: string, subscriptionId: string): Promise<void> {
  if (staticAreaId === null) {
    return
  }

  const areaSubscription = await getAreaSubscription(staticAreaId)
  if (areaSubscription.areaSubscriptionCallbacks[destination] === undefined ||
    areaSubscription.areaSubscriptionCallbacks[destination][subscriptionId] === undefined) {
    // wenn die subscription nicht (mehr) registriert ist, dann muss man diese auch nicht entfernen werden
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete areaSubscription.areaSubscriptionCallbacks[destination][subscriptionId]

  if (areaSubscription.areaSubscriptionCallbacks[destination] === undefined || Object.keys(areaSubscription.areaSubscriptionCallbacks[destination]).length === 0) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete areaSubscription.areaSubscriptionCallbacks[destination]
  }
}
